@import "../../../styles/style.module.scss";

.root {
  width: 100%;
  height: 100%;
  padding-top: 3px;

  @include for-size(phone) {
    padding: 0;
    padding-bottom: 25px;
  }
}

.rootContainer {
  margin: 20px;

  @media (max-width: 600px) {
    margin: 0;
    margin-top: 5px;
    margin-bottom: 50px;
  }
}

.membershipContainer {
  background: $white;
  @include border-radius(5px);
  @include box-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  width: 100%;
}

.membershipCardContainer {
  padding: 40px;
  @include flex(center, center, row);
}

.membershipCard {
  width: 420px;
  height: 220px;
  @include border-radius(10px);
  @include flex(center, flex-start, row);
  position: relative;
  padding: 40px;
}

.tabsRoot {
  display: block !important;
  padding: 10px 0;
  border-bottom: 0.5px solid rgba(2, 71, 91, 0.3);

  .tabRoot {
    width: 180px;
    text-transform: none !important;
  }

  @media (max-width: 600px) {
    margin-left: 0;
    margin-right: 0;
    background-color: $lightGrey;
  }
}

.tabsIndicator {
  position: relative;
  &::after {
    position: absolute;
    background-color: $tertiaryColor;
    height: 5px;
    content: "";
    bottom: 0;
    width: 100%;
    left: 0;
  }
}

.mcContent {
  @include flex(center, flex-start, row);

  img {
    margin: 0 20px 0 0;
  }
}

.mcUser {
  @include text-transform(capitalize);

  h2 {
    font-size: 16px;
    @include font-weight(500);
    margin: 0 0 10px;
  }

  p {
    font-size: 17px;
    @include font-weight(500);
    color: $emperor;
  }
}

.healthCredits {
  width: 270px;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  padding: 20px;
  background: $white;
  @include border-radius(10px);
  @include box-shadow(0px 5px 20px rgba(128, 128, 128, 0.3));
  @include flex(center, flex-start, row);
  margin: 0 auto;

  p {
    font-size: 16px;
    @include font-weight(500);
    margin: 0 15px;
  }

  h3 {
    font-size: 20px;
    @include font-weight(600);
  }
}

.transactionHeader {
  @include flex(center, space-between, row);
  padding: 30px;
  border-bottom: 1px solid $mercury;

  h2 {
    font-size: 16px;
    @include text-transform(uppercase);
    @include font-weight(500);
  }
}

.thDetails {
  p {
    font-size: 14px;
    @include font-weight(500);
    line-height: 18px;
    color: $emperor;
  }

  h2 {
    font-size: 16px;
    @include font-weight(600);
    line-height: 20px;
  }
}

.transactionList {
  margin: 0;
  padding: 0 30px;
  list-style: none;
  max-height: 500px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $seashell;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey16;
  }

  li {
    line-height: 1.5;
    font-size: 1rem;
    padding: 20px 0;
    border-bottom: 1px solid $mercury;

    &:last-child {
      border: none;
    }

    > p {
      font-size: 14px;
      color: $textGray;

      span {
        @include font-weight(600);
      }
    }
  }
}

.tContainer {
  @include flex(center, space-between, row);
}

.tContent {
  @include flex(center, flex-start, row);
  padding: 0 0 10px;
}

.hcDetails {
  margin: 0 0 0 20px;

  h3 {
    color: $black;
    font-size: 15px;
    @include font-weight(500);
  }

  p {
    font-size: 14px;
    @include font-weight(500);
    color: $textGray;
  }
}

.transactionDetails {
  p {
    font-size: 16px;
    @include font-weight(600);
    text-align: right;

    span {
      margin: 0 10px 0 0;
    }
  }
}

.earned {
  color: $tertiaryColor;
}

.redeemed {
  color: $cardinal;
}

.circularProgress {
  @include flex(flex-start, center, row);
  padding: 20px;
}
