@import "./../../../styles/style.module.scss";

.pageContainer {
  @include for-size(phone) {
    padding-top: 112px;
  }
  @include for-size(desktop) {
    padding-top: 102px;
  }
}

.container {
  max-width: 1064px;
  margin: auto;
}

.layout {
  @include flex(unset, unset, row);
  background-color: $white4;
  position: relative;

  .mainSection {
    flex-grow: 1;
  }

  @include for-size(phone) {
    display: block;
    background-color: transparent;
  }
}

.progressLoader {
  background: rgba(252, 183, 22, 0.6);
}
