@import "../../../../styles/style.module.scss";

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  & .backdrop {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  & .drawer-content {
    position: absolute;
    background-color: $white;
    width: 275px;
    height: 100%;
    @include box-shadow(0 0 10px rgba(0, 0, 0, 0.2));
    transform: translateX(-275px);
    transition: transform 0.3s ease-out;
    z-index: 1000;
    &.open {
      transform: translateX(0);
      overflow: auto;
    }
  }
}
