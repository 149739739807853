@import "./../../../styles/style.module.scss";

.root {
  @include for-size(desktop) {
    width: 100%;
    max-width: 328px;
    height: auto;
    padding: 10px;
    margin: 0;
    background-color: $white4;
    padding-left: 15px;
    margin-right: 10px;
    padding-top: 13px;
  }

  @include for-size(phone) {
    background-color: transparent;
  }
}

.container {
  @include for-size(phone) {
    max-width: 286px;
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 25px;
    height: calc(100% + 48px);
  }
}

.drawer {
  display: none;

  @include for-size(phone) {
    display: block;
  }

  .drawerContent {
    padding-right: 25px;
    padding-top: 80px;
    padding-left: 10px;
    max-width: 286px;
  }
}

.sidebarContainer {
  display: none;

  @include for-size(desktop) {
    display: block;
  }
}

.tab {
  @include flex(center, space-between, row);
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.3);
  border: 1px solid white;
  @include border-radius(10px);
  width: 100%;
  padding: 10px;
  margin: 10px;
  color: $primaryColor;
  font-size: 14px;
  @include font-weight(500);
  min-height: 54px;
  cursor: pointer;

  img {
    max-width: 25px;
  }
}

.activeTab {
  border: 1px solid $tertiaryColor;
}

.tabText {
  margin-left: 10px;
  letter-spacing: normal;
}

.menuToggleBtn {
  background-color: white;
  @include box-shadow(0 5px 20px 0 rgba(128, 128, 128, 0.3));
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 2;
  top: 56px;
  left: 0;

  button {
    box-shadow: none;
    padding: 10px 16px;
    min-width: auto;
    @include border-radius(0);
  }

  @include for-size(desktop) {
    display: none;
  }
}

.pageTitle {
  @include text-transform(uppercase);
  flex-grow: 1;
  @include text-align(center);
  margin-left: -56px;
  color: $primary2;
  @include font-weight(500);
  line-height: 56px;
}

.flexCenter {
  @include flex(center, center, unset);
}

.menuIcon {
  font-size: 20;
  @include font-weight(700);
}
